import React from "react";
import { FaBriefcase } from "react-icons/fa";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.css";

const Timeline = () => {
	return (
		<div>
			<VerticalTimeline>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Jan 2024 - Current"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">Intuit</h3>
					<h4 className="vertical-timeline-element-subtitle">
						Full Stack Developer (Contract)
					</h4>
					<p>
						Develop and maintain scalable front-end (React,
						TypeScript) and back-end (Java, Python) components.
						Build and optimize REST/GraphQL APIs, manage SQL/NoSQL
						databases, and implement caching (Redis, Memcached) for
						performance.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Jan 2024 - Jun 2024"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						Research Assistant
					</h3>
					<h4 className="vertical-timeline-element-subtitle">NJIT</h4>
					<p>
						Designed a Bloom Filter for searching through encrypted
						files on a public cloud. The algorithm provides
						encryption for data to be stored publicly while yet
						enabling a search functionality.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Jan 2023 - Dec 2023"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						Library Research Assistant
					</h3>
					<h4 className="vertical-timeline-element-subtitle">NJIT</h4>
					<p>
						Developed an intelligent bot to efficiently retrieve
						user-relevant patents and research papers from the
						library's extensive database, enhancing accessibility
						and research productivity.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Sep 2021 - Aug 2022"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						Automation & Dev Ops Engineer
					</h3>
					<h4 className="vertical-timeline-element-subtitle">
						AppPerfect - VERIZON
					</h4>
					<p>
						Automated Dockerization of application components,
						Debian package creation, and deployment on AWS clusters,
						resulting in a 50% acceleration in deployment time with
						Ansible playbooks.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Jan 2020 - Aug 2021"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						NLP Engineer & Backend Developer
					</h3>
					<h4 className="vertical-timeline-element-subtitle">
						AppPerfect - Automation Anywhere
					</h4>
					<p>
						Created an NLP solution using Natural Language
						Processing and Spring Boot (Java) to categorize email
						data, achieving a 5x reduction in client response time
						with 95% accuracy, thereby improving user satisfaction.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="April 2019 - Dec 2019"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						Software Engineer
					</h3>
					<h4 className="vertical-timeline-element-subtitle">
						AppPerfect
					</h4>
					<p>
						Enhanced AppPerfect's in-house Load Test Application by
						upgrading the codebase to Java 1.8, debugging issues,
						and ensuring compatibility with Windows 11 and macOS 11+
						versions.
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className="vertical-timeline-element--work"
					contentStyle={{ background: "#333", color: "#fff" }}
					contentArrowStyle={{ borderRight: "7px solid #c084f5" }}
					date="Feb 2018 - March 2019"
					iconStyle={{ background: "#c084f5", color: "#fff" }}
					icon={<FaBriefcase />}
				>
					<h3 className="vertical-timeline-element-title">
						Full Stack Developer
					</h3>
					<h4 className="vertical-timeline-element-subtitle">
						SagesoftTech
					</h4>
					<p>
						Developed optimized data extraction scripts reducing
						processing time by 80%. Engineered backend with Express,
						PostgreSQL, and AWS EC2, adding Redis cache on Jenkins
						for 25% downtime reduction. Built scalable microservices
						with Spring Boot/Cloud. Led Mobihealth app, boosting
						user satisfaction by 25%.
					</p>
				</VerticalTimelineElement>
			</VerticalTimeline>
		</div>
	);
};

export default Timeline;
