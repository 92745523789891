import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import "./home.css";

function Home() {
	return (
		<section>
			<Container fluid className="home-main">
				<Container fluid className="home-section" id="home">
					<Particle />
					<Container className="home-content">
						<Row>
							<Col md={7} className="home-header">
								<h1
									style={{ paddingBottom: 15 }}
									className="heading"
								>
									Hi There!{" "}
									<span
										className="wave"
										role="img"
										aria-labelledby="wave"
									>
										👋🏻
									</span>
								</h1>

								<h1 className="heading-name">
									I'M
									<strong className="main-name">
										{" "}
										KARAN GAUR
									</strong>
								</h1>

								<div style={{ padding: 50, textAlign: "left" }}>
									<Type />
								</div>
							</Col>

							<Col md={5} style={{ paddingBottom: 20 }}>
								<div className="home-img" />
							</Col>
						</Row>
					</Container>
				</Container>
				<Home2 />
			</Container>
		</section>
	);
}

export default Home;
