import useEmblaCarousel from "embla-carousel-react";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";
import "./Projects.css"; // Custom CSS for styling
import projects from "./projects.json";

function Projects() {
	const [viewportRef, embla] = useEmblaCarousel({
		loop: true, // Enable looping
		draggable: true,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [embla, onSelect]);

	return (
		<Container fluid className="project-section">
			<Particle />
			<div className="main-container">
				<div>
					<h1 className="project-heading">
						My Recent <strong className="purple">Works</strong>
					</h1>
					<p style={{ color: "white" }}>
						Here are a few projects I've worked on recently.
					</p>
				</div>
				<div className="embla">
					<div className="embla__viewport" ref={viewportRef}>
						<div className="embla__container">
							{projects.map((project, index) => {
								return (
									<div key={index} className="embla__slide">
										<ProjectCard
											imgPath={project.imgPath}
											isBlog={project.isBlog}
											title={project.title}
											description={project.description}
											ghLink={project.ghLink}
											demoLink={project.demoLink}
										/>
									</div>
								);
							})}
						</div>
					</div>

					{/* Embla Controls with SVG Icons */}
					<button
						className="embla__button embla__button--prev"
						onClick={scrollPrev}
						disabled={!prevBtnEnabled}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="white"
							width="24px"
							height="24px"
						>
							<path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
						</svg>
					</button>
					<button
						className="embla__button embla__button--next"
						onClick={scrollNext}
						disabled={!nextBtnEnabled}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="white"
							width="24px"
							height="24px"
						>
							<path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
						</svg>
					</button>
				</div>
			</div>
		</Container>
	);
}

export default Projects;
